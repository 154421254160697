import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CredentialsService } from '@app/core';
import { DataService } from '../services/data/data.service';
import { ApiService } from '../services/api/api.service';
import { Coords, Emergency, LocationPost } from '@app/core/db/emergency.model';
import { basicMap, mapOptions, setControl } from '@app/core/map/mapconstants.module';
import { Follow } from '@app/core/db/follow.model';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { LayerGroup, Map } from 'leaflet';
import * as L from 'leaflet';
import * as cdmxQuadData from '@app/core/map/cdmxquadrants.json';
import * as agebs from '@app/core/map/agebs.json';
import * as zoneData from '@app/core/map/trust-11b7d-risk-zones-export.json';
import { Subject, Subscription } from 'rxjs';
import { environment } from '@env/environment';
const omnivoreModule = require('../../../assets/js/leaflet-omnivore.min.js');

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  userAuth: any;
  omnivore = omnivoreModule;
  sendReadyMap = new Subject<boolean>();
  sendReadyMap$ = this.sendReadyMap.asObservable();
  map: Map;
  mapLayer = basicMap;
  quadPol = new L.LayerGroup();
  zonePol = new L.LayerGroup();
  activeEmergency = new L.LayerGroup();
  emergencyGP = new L.LayerGroup();
  oldEmergencyGP = new L.LayerGroup();
  postGp = new L.LayerGroup();
  postCarGp = new L.LayerGroup();
  lastLocGp = new L.LayerGroup();
  lastLocMk = new L.LayerGroup();
  lastLocLn = new L.LayerGroup();
  lastPosts = new L.LayerGroup();
  lastCarsPosts = new L.LayerGroup();
  membersPost = new L.LayerGroup();
  unitsPost = new L.LayerGroup();
  postSng = new L.LayerGroup();
  crimeGp = new L.LayerGroup();
  layerOrigin = new L.LayerGroup();
  layerDestination = new L.LayerGroup();
  corporateGeoLayer = new L.LayerGroup();
  geofencesLayer = new L.LayerGroup();
  arrowLine = new L.LayerGroup();
  tmpSng: L.Marker;
  quadrantsCdmx = cdmxQuadData.default;
  agebs = agebs.default;
  setControl = setControl;
  lineArr: any[] = [];
  crimeCats = [
    'HOMICIDIO DOLOSO',
    'LESIONES DOLOSAS POR DISPARO DE ARMA DE FUEGO',
    'ROBO A CASA HABITACIÓN CON VIOLENCIA',
    'ROBO A CUENTAHABIENTE SALIENDO DEL CAJERO CON VIOLENCIA',
    'ROBO A NEGOCIO CON VIOLENCIA',
    'ROBO A PASAJERO A BORDO DE MICROBUS CON Y SIN VIOLENCIA',
    'ROBO A PASAJERO A BORDO DE TAXI CON VIOLENCIA',
    'ROBO A PASAJERO A BORDO DEL METRO CON Y SIN VIOLENCIA',
    'ROBO A REPARTIDOR CON Y SIN VIOLENCIA',
    'ROBO A TRANSEUNTE EN VÍA PÚBLICA CON Y SIN VIOLENCIA',
    'ROBO DE VEHÍCULO CON Y SIN VIOLENCIA',
    'VIOLACIÓN'
  ];
  zoneLevels = [
    {
      id: 'very_high',
      name: 'MUY ALTO',
      color: '#cc3232'
    },
    {
      id: 'high',
      name: 'ALTO',
      color: '#db7b2b'
    },
    {
      id: 'medium',
      name: 'MEDIO',
      color: '#e7b416'
    },
    {
      id: 'low',
      name: 'BAJO',
      color: '#99c140'
    },
    {
      id: 'very_low',
      name: 'MUY BAJO',
      color: '#2dc937'
    },
    {
      id: 'none',
      name: 'NINGUNO',
      color: '#808080'
    }
  ];
  corporategeo: Array<any> = [];
  tmpcorporategeo: Array<any> = [];
  drawGeofences: boolean = true;
  corZonesSub: Subscription;
  l = L;
  index: any;

  constructor(
    public datePipe: DatePipe,
    private creds: CredentialsService,
    private dataService: DataService,
    public apiService: ApiService,
    public http: HttpClient,
    public router: Router
  ) {
    this.userAuth = this.creds.credentials;
  }

  async ngOnInit() {
    this.createMap();
  }

  createMap() {
    setTimeout(async () => {
      try {
        this.initMap();
      } catch (error) {
        console.error('error loading map', error);
      }
    }, 1500);
  }

  removeMap() {
    this.map.eachLayer((layer: L.Layer) => {
      this.map.removeLayer(layer);
    });
    this.map.remove();
    document.getElementById('mapId').outerHTML = '';
  }

  ionViewWillLeave() {
    this.sendReadyMap.next(false);
  }

  async initMap() {
    const map_container = !!document.getElementById('mapId');
    if (map_container) {
      this.map = await new Map('mapId', mapOptions);

      const tree: UrlTree = this.router.parseUrl(this.router.url);
      const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
      const s: UrlSegment[] = g.segments;
      const rootPath = s[0].path;

      if (rootPath == 'geofences') {
        this.map.pm.addControls({
          position: 'topleft',
          drawMarker: false,
          drawPolyline: false,
          drawCircleMarker: false,
          cutPolygon: false
        });

        this.map.pm.setGlobalOptions({
          layerGroup: this.corporateGeoLayer,
          snappingOrder: ['Marker', 'CircleMarker', 'Circle', 'Line', 'Polygon', 'Rectangle'],
          panes: { vertexPane: 'markerPane', layerPane: 'overlayPane', markerPane: 'markerPane' }
        });

        this.subscribetoevents();
      } else {
        this.cuadrantsMapping();
        if (environment.GROUP == '' && this.creds.credentials.group == '') {
          this.riskZoneMapping();
        }
      }

      this.sendReadyMap.next(true);
      this.mapLayer.addTo(this.map);
      this.map.addControl(this.setControl);
      setTimeout(() => {
        this.refreshMap();
      }, 1000);
    }

    if (environment.GROUP != '' || this.creds.credentials.group != '') {
      const group = environment.GROUP != '' ? environment.GROUP : this.creds.credentials.group;
      this.corporateZones(group.toLowerCase());
    } else {
      this.corporateZones('risk');
    }
  }

  refreshMap() {
    this.mapLayer.redraw();
    this.map.invalidateSize();
  }

  riskZoneMapping() {
    const zones = zoneData.default;
    // tslint:disable-next-line: forin
    for (const x in zones) {
      const y = new L.Polygon(zones[x].points, {
        color: zones[x].fillColor
      });
      this.zonePol.addLayer(y);
    }
    this.map.addLayer(this.zonePol);
    this.setControl.addOverlay(this.zonePol, 'Zonas de Riesgo');
  }

  cuadrantsMapping() {
    const dataArr: any[] = this.quadrantsCdmx.features;
    const myStyle = {
      color: '#6fcfd6',
      weight: 4,
      opacity: 0.65
    };

    dataArr.forEach((single: any) => {
      const y = new L.GeoJSON(single.geometry, {
        style: myStyle
      });

      const popUp =
        '<p><small>Sector</small> <b>' +
        single.properties.sector +
        '</b><br><small>Delegación</small> ' +
        single.properties.deleg +
        '<br><small>Nomenclatura</small> ' +
        single.properties.nomenclatu +
        '<br><small>Zona</small> ' +
        single.properties.zona +
        '<br><small>Teléfono 1</small> ' +
        single.properties.tel1 +
        '<br><small>Teléfono 2</small> ' +
        single.properties.tel2 +
        '</p>';
      y.bindPopup(popUp);
      y.addTo(this.quadPol);
    });

    this.setControl.addOverlay(this.quadPol, 'Cuadrantes CDMX');
  }

  async clearLayer() {
    try {
      this.map.eachLayer((layer: L.Layer) => {
        layer.remove();
      });
    } catch (error) {}
  }

  //CLEAR ZONE
  clearHome() {
    this.removeHome();
  }

  clearEmergency() {
    this.removeEmergency();
  }

  clearCircle() {
    this.removeCircle();
  }

  clearPerson() {
    this.removePerson();
  }

  clearCar() {
    this.removeCar();
  }

  clearFollow() {
    this.removeFollow();
  }

  //REMOVE ZONE
  removeHome() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.emergencyGP);
    this.setControl.removeLayer(this.oldEmergencyGP);
  }

  removeEmergency() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.postGp);
    this.setControl.removeLayer(this.lastPosts);
    this.setControl.removeLayer(this.lastLocGp);
    this.setControl.removeLayer(this.lastLocLn);
    this.map.removeLayer(this.activeEmergency);
  }

  removeCircle() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.membersPost);
    this.setControl.removeLayer(this.unitsPost);
    this.zonePol.clearLayers();
    this.quadPol.clearLayers();
    this.membersPost.clearLayers();
    this.unitsPost.clearLayers();
  }

  removePerson() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.postGp);
    this.zonePol.clearLayers();
    this.quadPol.clearLayers();
    this.postSng.clearLayers();
    this.postGp.clearLayers();
    this.map.removeLayer(this.postSng);
    this.map.removeLayer(this.postGp);
  }

  removeCar() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.postGp);
    this.zonePol.clearLayers();
    this.quadPol.clearLayers();
    this.postSng.clearLayers();
    this.postGp.clearLayers();
    this.map.removeLayer(this.postSng);
    this.map.removeLayer(this.postGp);
  }

  //HOME POSTS
  postHomeMapping() {
    /* const myStyle = {
      color: '#6fcfd6',
      weight: 4,
      opacity: 0.65
    };

    this.agebs.data.forEach((single: any) => {
      const y = new L.GeoJSON(single.geojson.geometry, {
        style: myStyle
      });

      new L.GeoJSON(single.geojson).addTo(this.map);
    }); */

    this.map.addLayer(this.emergencyGP);
    this.setControl.addOverlay(this.emergencyGP, 'Emergencias activas');
    //this.map.addLayer(this.oldEmergencyGP);
    this.setControl.addOverlay(this.oldEmergencyGP, 'Emergencias pasadas');
  }

  singleHomePost(data: Emergency) {
    let iconUrl: string;
    let iconSize: L.PointExpression;
    let iconAnchor: L.PointExpression;
    let popupAnchor: L.PointExpression;
    if (!data.emergencySolved) {
      iconUrl = './assets/icon/' + data.type + '-sos.png';
      iconSize = [50, 50];
      iconAnchor = [25, 5];
      popupAnchor = [0, -5];
    } else {
      iconUrl = './assets/icon/' + data.type + '-old.png';
      iconSize = [32, 32];
      iconAnchor = [16, 5];
      popupAnchor = [0, -5];
    }
    const icon = L.icon({
      iconUrl,
      iconSize,
      iconAnchor,
      popupAnchor
    });
    const person = (data.username ? data.username : '') + ' ' + (data.owner ? data.owner : '');
    const tmp = new L.Marker([data.coord.latitude, data.coord.longitude], {
      icon,
      zIndexOffset: 12000
    }).bindPopup('<h5>' + data.circle + '</h5>' + '<h6>' + person + '</h6>' + '<p>' + data.published.toDate() + '</p>');
    if (!data.emergencySolved) {
      tmp.addTo(this.emergencyGP);
    } else {
      tmp.addTo(this.oldEmergencyGP);
    }
  }

  //EMERGENCY POSTS
  postEmergencyMapping() {
    this.map.addLayer(this.lastLocLn);
    this.map.addLayer(this.lastLocMk);
    this.map.addLayer(this.postGp);
    this.map.addLayer(this.activeEmergency);
    this.setControl.addOverlay(this.postGp, 'Últimas ubicaciones');
    this.setControl.addOverlay(this.lastLocGp, 'Live Tracking (puntos)');
    this.setControl.addOverlay(this.lastLocLn, 'Live Tracking (línea)');
  }

  singleemergencypost(data: Emergency, username: string) {
    const icon = L.icon({
      iconUrl: './assets/icon/' + data.type + '-sos.png',
      iconAnchor: [34, 5],
      iconSize: [68, 68],
      popupAnchor: [0, -5]
    });
    L.marker([data.coord.latitude, data.coord.longitude], {
      icon,
      zIndexOffset: 1000
    })
      .addTo(this.activeEmergency)
      .bindPopup(
        '<ion-text color="danger"><h3 class="ion-text-center">Emergencia</h3></ion-text>' +
          '<h5 class="ion-text-center">' +
          username +
          '</h5>' +
          '<p>' +
          this.datePipe.transform(data.published.seconds * 1000, 'medium') +
          '<br>' +
          data.direction +
          '</p>'
      );

    this.sendReadyMap$.subscribe(ready => {
      if (ready) {
        this.map.setView([data.coord.latitude, data.coord.longitude], 15);
      }
    });
  }

  postsMapping(posts: Array<any>) {
    this.postGpMapping(posts);
  }

  async postGpMapping(posts: Array<any>) {
    this.postGp.clearLayers();
    await posts.map(data => {
      let username;
      switch (data.type) {
        case 'car':
          username = data.cid + ' - ' + data.name_device;
          break;

        default:
          username = data.username ? data.username : data.owner;
          break;
      }

      const icon = L.icon({
        iconUrl: './assets/icon/' + data.type + '-old.png',
        iconAnchor: [25, 5],
        iconSize: [50, 50],
        popupAnchor: [0, -5]
      });
      const popUp =
        '<h4 class="ion-text-center">' +
        username +
        '</h4>' +
        '<p>' +
        data.direction +
        '</p>' +
        '<p>' +
        this.datePipe.transform(data.published, 'medium') +
        '</p>';
      const tmp = new L.Marker([data.coord.latitude, data.coord.longitude], {
        icon
      })
        .bindPopup(popUp)
        .addTo(this.postGp);
    });
  }

  async lastLocMapping(lastlocs: Array<LocationPost>) {
    this.lastLocGp.clearLayers();
    this.lastLocLn.clearLayers();
    this.lineArr = [];
    await lastlocs
      .sort((a, b) => a.published.seconds - b.published.seconds)
      .forEach(item => {
        this.lineArr.push([item.coord.latitude, item.coord.longitude]);
      });
  }

  lastlocmapping(data: LocationPost, index: number, lastprinted: LocationPost) {
    this.arrowLine.clearLayers();
    this.setControl.removeLayer(this.arrowLine);

    if (index === 0) {
      //console.log('Case : Last Loc', len + ' Index', index + 1, 'Length', len === index + 1);
      // si es el último objeto se pinta de azul (live location)
      const icon = new L.DivIcon({
        iconSize: [100, 100],
        className: 'my-div-icon',
        html:
          '<img class="my-div-image" src="./assets/icon/circle-reg.png"/>' +
          '<span class="my-div-span">Última <br> ubicación</span>'
      });
      this.printMarker(icon, data, 'reg');
    } else {
      let icon;
      if (index == this.lineArr.length - 1) {
        icon = new L.DivIcon({
          iconSize: [100, 100],
          className: 'my-div-icon',
          html:
            '<img class="my-div-image" src="./assets/icon/pin-reg.png"/>' + '<span class="my-div-span">Inicio</span>'
        });
        this.printMarker(icon, data, 'old');
      } else if (this.getDistanceFromLatLonInKm(data.coord, lastprinted.coord) > 0.15) {
        let icon;

        // si hay mas de 50 mts de diferencia del último punto
        icon = L.icon({
          iconUrl: './assets/icon/circle-old.png',
          iconSize: [24, 24]
        });

        this.printMarker(icon, data, 'old');
      }

      const ln = new L.Polyline(this.lineArr, {
        color: '#1abc9c',
        smoothFactor: 1,
        opacity: 0.05
      });

      ln.addTo(this.lastLocLn);
      this.arrowLine = L.featureGroup(this.getArrows(this.lineArr, 'teal', 5, this.map));

      this.setControl.addOverlay(this.arrowLine, 'Seguimiento');
    }
  }

  /* ARROWS */
  /* GET MORE INFO AT: https://stackoverflow.com/a/58686215 */
  getArrows(arrLatlngs: any, color: any, arrowCount: any, mapObj: any) {
    if (typeof arrLatlngs === undefined || arrLatlngs == null || !arrLatlngs.length || arrLatlngs.length < 2) return [];

    if (typeof arrowCount === 'undefined' || arrowCount == null) arrowCount = 1;

    if (typeof color === 'undefined' || color == null) color = '';
    else color = 'color:' + color;

    var result = [];
    this.index = 0;
    for (var i = 1; i < arrLatlngs.length; i++) {
      var icon = L.divIcon({
        className: 'arrow-icon',
        bgPos: [3, 3],
        html:
          '<div style="' +
          color +
          ';transform: rotate(' +
          this.getAngle(arrLatlngs[i - 1], arrLatlngs[i], -1).toString() +
          'deg)"><ion-icon name="caret-forward-outline" style="font-size:2em"></ion-icon></div>'
      });
      for (var c = 1; c <= arrowCount; c++) {
        const marker = L.marker(this.myMidPoint(arrLatlngs[i], arrLatlngs[i - 1], c / (arrowCount + 1), mapObj), {
          icon: icon
        });
        result.push(marker);
      }
    }
    return result;
  }

  getAngle(latLng1: any, latlng2: any, coef: any) {
    var dy = latlng2[0] - latLng1[0];
    var dx = Math.cos((Math.PI / 180) * latLng1[0]) * (latlng2[1] - latLng1[1]);
    var ang = (Math.atan2(dy, dx) / Math.PI) * 180 * coef;
    return ang.toFixed(2);
  }

  myMidPoint(latlng1: any, latlng2: any, per: any, mapObj: any) {
    if (!mapObj) throw new Error('map is not defined');

    var halfDist,
      segDist,
      dist,
      p1,
      p2,
      ratio,
      points = [];

    p1 = mapObj.project(new L.LatLng(latlng1[0], latlng1[1]));
    p2 = mapObj.project(new L.LatLng(latlng2[0], latlng2[1]));

    halfDist = this.distanceTo(p1, p2) * per;

    if (halfDist === 0) return mapObj.unproject(p1);

    dist = this.distanceTo(p1, p2);

    if (dist > halfDist) {
      ratio = (dist - halfDist) / dist;
      var res = mapObj.unproject(this.Point(p2.x - ratio * (p2.x - p1.x), p2.y - ratio * (p2.y - p1.y)));
      return [res.lat, res.lng];
    }
  }

  distanceTo(p1: any, p2: any) {
    var x = p2.x - p1.x,
      y = p2.y - p1.y;

    return Math.sqrt(x * x + y * y);
  }

  toPoint(x: any, y: any, round?: any) {
    if (x instanceof this.Point) {
      return x;
    }
    if (x.isArray()) {
      return this.Point(x[0], x[1]);
    }
    if (x === undefined || x === null) {
      return x;
    }
    if (typeof x === 'object' && 'x' in x && 'y' in x) {
      return this.Point(x.x, x.y);
    }
    return this.Point(x, y, round);
  }

  Point(x: any, y: any, round?: any) {
    const x1 = round ? Math.round(x) : x;
    const y1 = round ? Math.round(y) : y;

    return { x: x1, y: y1 };
  }
  /* END 
  ARROWS */

  printMarker(icon: L.Icon | L.DivIcon, data: LocationPost, type: string) {
    const popUp =
      '<h4 class="ion-text-center">' +
      data.username +
      '</h4>' +
      '<p>' +
      data.direction +
      '</p>' +
      '<p>' +
      this.datePipe.transform(data.published.toDate(), 'medium') +
      '</p>';
    const tmp = new L.Marker([data.coord.latitude, data.coord.longitude], {
      icon
    });
    tmp['key'] = data.id ? data.id : null;

    tmp.bindPopup(popUp);
    if (type === 'reg') {
      this.lastLocMk.clearLayers();
      tmp.addTo(this.lastLocMk);
    } else if (type === 'old') {
      tmp.addTo(this.lastLocGp);
    }

    return data;
  }

  //CIRCLE POSTS
  postCircleMapping() {
    this.undoCircleMapping();
    this.map.addLayer(this.membersPost);
    this.map.addLayer(this.unitsPost);
    this.map.addLayer(this.geofencesLayer);
    this.setControl.addOverlay(this.membersPost, 'Miembros');
    this.setControl.addOverlay(this.unitsPost, 'Unidades');
    this.setControl.addOverlay(this.geofencesLayer, 'Geocercas');
  }

  undoCircleMapping() {
    try {
      this.setControl.removeLayer(this.membersPost);
      this.setControl.removeLayer(this.unitsPost);
      this.setControl.removeLayer(this.geofencesLayer);
    } catch (error) {}
  }

  circleMemberPost(memberData: any) {
    const date = new Date(memberData.lastPost.published);
    const iconSuffix: string =
      memberData.lastPost.emergency || memberData.lastPost.emergency === 1 ? '-sos.png' : '-reg.png';
    const icon = L.icon({
      iconUrl: './assets/icon/' + memberData.lastPost.type + iconSuffix,
      iconAnchor: [34, 64],
      iconSize: [68, 68],
      popupAnchor: [0, -64]
    });

    const popUp =
      '<h4 class="ion-text-center">' +
      memberData.username +
      '</h4>' +
      '<p>' +
      memberData.lastPost.direction +
      '</p>' +
      '<p>' +
      date +
      '</p>';
    const tmp = new L.Marker([memberData.lastPost.coord.latitude, memberData.lastPost.coord.longitude], {
      icon,
      zIndexOffset: 1000
    }).bindPopup(popUp);

    tmp['username'] = memberData.username;

    this.membersPost.eachLayer(layer => {
      if (layer['username'] == memberData.username) {
        this.membersPost.removeLayer(layer);
      }
    });

    tmp.addTo(this.membersPost);
  }

  circleUnitPost(unitData: any, index: number) {
    const date = new Date(unitData.lastPost.published);
    const iconSuffix: string =
      unitData.lastPost.emergency || unitData.lastPost.emergency === 1 ? '-sos.png' : '-reg.png';
    const icon = L.icon({
      iconUrl: './assets/icon/' + unitData.lastPost.type + iconSuffix,
      iconAnchor: [34, 64],
      iconSize: [68, 68],
      popupAnchor: [0, -64]
    });

    const popUp =
      '<h4 class="ion-text-center">' +
      unitData.name_device +
      '</h4>' +
      '<p>' +
      unitData.lastPost.direction +
      '</p>' +
      '<p>' +
      date +
      '</p>';
    const tmp = new L.Marker([unitData.lastPost.coord.latitude, unitData.lastPost.coord.longitude], {
      icon,
      zIndexOffset: 1000
    }).bindPopup(popUp);

    tmp['username'] = unitData.name_device;
    tmp['key'] = unitData.imei;

    let add = false;

    if (index > -1) {
      this.unitsPost.eachLayer((marker: L.Marker) => {
        if (marker['key'] == unitData.imei) {
          const newLatLng = new L.LatLng(unitData.lastPost.coord.latitude, unitData.lastPost.coord.longitude);
          marker.setLatLng(newLatLng);
          marker.setPopupContent(popUp);
        }
      });
    } else {
      add = true;
    }

    if (add) {
      tmp.addTo(this.unitsPost);
    }
  }

  circleSinglePost(data: any, index: number) {
    const date = new Date(data.published);
    const icon = L.icon({
      iconUrl: './assets/icon/' + data.type + '-old.png',
      iconAnchor: [25, 47],
      iconSize: [50, 50],
      popupAnchor: [0, -47]
    });
    const popUp =
      '<h4 class="ion-text-center">' +
      data.username +
      '</h4>' +
      '<p>' +
      data.direction +
      '</p>' +
      '<p>' +
      date +
      '</p>';

    let tmp = new L.Marker([data.coord.latitude, data.coord.longitude], {
      icon
    }).bindPopup(popUp);
    tmp['key'] = data.key;

    let add = false;

    if (index !== -1) {
      this.postGp.eachLayer((marker: L.Marker) => {
        if (marker['key'] == data.key) {
          const newLatLng = new L.LatLng(data.coord.latitude, data.coord.longitude);
          marker.setLatLng(newLatLng);
          marker.setPopupContent(popUp);
        }
      });
    } else {
      add = true;
    }

    if (add) {
      tmp.addTo(this.postGp);
    }
  }

  circleGeofence(geofence: any) {
    /* const date = new Date(memberData.lastPost.published);
    const iconSuffix: string =
      memberData.lastPost.emergency || memberData.lastPost.emergency === 1 ? '-sos.png' : '-reg.png';
    const icon = L.icon({
      iconUrl: './assets/icon/' + memberData.lastPost.type + iconSuffix,
      iconAnchor: [34, 64],
      iconSize: [68, 68]
    }); */
    let desc = '';
    switch (geofence.transitionType) {
      case 1:
        desc = 'Entrada';
        break;
      case 2:
        desc = 'Salida';
        break;
      case 3:
        desc = 'Entrada y Salida';
        break;
      default:
        break;
    }
    const popUp = '<h4 class="ion-text-center">' + geofence.notification.text + '</h4>' + '<p>' + desc + '</p>';

    const circle = new this.l.Circle({ lat: geofence.latitude, lng: geofence.longitude }, geofence.radius);
    circle['key'] = JSON.stringify(geofence.id);
    circle.bindPopup(popUp);

    this.geofencesLayer.eachLayer(layer => {
      if (layer['key'] == geofence.id) {
        this.geofencesLayer.removeLayer(layer);
      }
    });

    circle.addTo(this.geofencesLayer);
  }

  //PERSON POSTS
  postPersonMapping() {
    this.map.addLayer(this.postGp);
    this.map.addLayer(this.postSng);
    this.setControl.addOverlay(this.postGp, 'Historial de ubicaciones');
  }

  personSinglePost(user: any) {
    const iconSuffix: string = user.lastPost ? (user.lastPost.emergency ? '-sos.png' : '-reg.png') : '-reg.png';
    const icon = L.icon({
      iconUrl: './assets/icon/' + user.lastPost.type + iconSuffix,
      iconAnchor: [34, 5],
      iconSize: [68, 68],
      popupAnchor: [0, -5]
    });
    let date = new Date(user.lastPost.published);
    let popUp =
      '<h4 class="ion-text-center">' +
      user.username +
      '</h4>' +
      '<p>' +
      user.lastPost.body +
      '</p>' +
      '<p>' +
      user.lastPost.direction +
      '</p>' +
      '<p>' +
      date +
      '</p>';

    if (this.tmpSng) {
      this.postSng.removeLayer(this.tmpSng);
    }

    this.tmpSng = new L.Marker([user.lastPost.coord.latitude, user.lastPost.coord.longitude], {
      icon,
      zIndexOffset: 1000
    }).bindPopup(popUp);
    this.tmpSng.addTo(this.postSng);

    return this.tmpSng;
  }

  personPost(data: Emergency, index: number) {
    let date = new Date(data.published);

    const icon = L.icon({
      iconUrl: './assets/icon/' + data.type + '-old.png',
      iconAnchor: [25, 5],
      iconSize: [50, 50],
      popupAnchor: [0, -5]
    });
    let popUp =
      '<h4 class="ion-text-center">' +
      data.username +
      '</h4>' +
      '<p>' +
      data.body +
      '</p>' +
      '<p>' +
      data.direction +
      '</p>' +
      '<p>' +
      date +
      '</p>';
    const tmp = new L.Marker([data.coord.latitude, data.coord.longitude], {
      icon
    }).bindPopup(popUp);

    let add = false;

    if (index !== -1) {
      this.postGp.eachLayer((marker: L.Marker) => {
        if (marker['key'] == data.key) {
          const newLatLng = new L.LatLng(data.coord.latitude, data.coord.longitude);
          marker.setLatLng(newLatLng);
          marker.setPopupContent(popUp);
        }
      });
    } else {
      add = true;
    }

    if (add) {
      tmp.addTo(this.postGp);
    }
  }

  gpsSinglePost(user: any) {
    const iconSuffix: string = user.lastPost ? (user.lastPost.emergency ? '-sos.png' : '-reg.png') : '-reg.png';
    const icon = L.icon({
      iconUrl: './assets/icon/' + user.lastPost.type + iconSuffix,
      iconAnchor: [34, 5],
      iconSize: [68, 68],
      popupAnchor: [0, -5]
    });
    let date = new Date(user.lastPost.published);
    const tmpSng = new L.Marker([user.lastPost.coord.latitude, user.lastPost.coord.longitude], {
      icon,
      zIndexOffset: 1000
    }).bindPopup(
      '<h4 class="ion-text-center">' +
        user.owner +
        '</h4>' +
        '<p>' +
        user.lastPost.body +
        '</p>' +
        '<p>' +
        user.lastPost.direction +
        '</p>' +
        '<p>' +
        date +
        '</p>'
    );

    tmpSng.addTo(this.postSng);
  }

  gpsPost(data: Emergency, index: number) {
    let date = new Date(data.published);

    const icon = L.icon({
      iconUrl: './assets/icon/' + data.type + '-old.png',
      iconAnchor: [25, 5],
      iconSize: [50, 50],
      popupAnchor: [0, -5]
    });
    const popUp =
      '<h4 class="ion-text-center">' +
      data.owner +
      '</h4>' +
      '<p>' +
      data.body +
      '</p>' +
      '<p>' +
      data.direction +
      '</p>' +
      '<p>' +
      date +
      '</p>';
    const tmp = new L.Marker([data.coord.latitude, data.coord.longitude], {
      icon
    }).bindPopup(popUp);

    let add = false;

    if (index !== -1) {
      this.postGp.eachLayer((marker: L.Marker) => {
        if (marker['key'] == data.key) {
          const newLatLng = new L.LatLng(data.coord.latitude, data.coord.longitude);
          marker.setLatLng(newLatLng);
          marker.setPopupContent(popUp);
        }
      });
    } else {
      add = true;
    }

    if (add) {
      tmp.addTo(this.postGp);
    }
  }

  //CAR POSTS
  postCarMapping() {
    this.map.addLayer(this.postGp);
    this.map.addLayer(this.postSng);
    this.setControl.addOverlay(this.postGp, 'Historial de ubicaciones');
  }

  carSinglePost(car: any) {
    if (car.lastPost) {
      const iconSuffix: string = car.lastPost.emergency || car.lastPost.emergency === 1 ? '-sos.png' : '-reg.png';
      const icon = L.icon({
        iconUrl: './assets/icon/' + car.lastPost.type + iconSuffix,
        iconAnchor: [34, 64],
        iconSize: [68, 68],
        popupAnchor: [0, -64]
      });
      const date = new Date(car.lastPost.published);
      const popUp =
        '<h4 class="ion-text-center">' +
        car.name_device +
        '</h4>' +
        '<p>' +
        car.lastPost.direction +
        '</p>' +
        '<p>' +
        date +
        '</p>';

      if (this.tmpSng) {
        this.postSng.removeLayer(this.tmpSng);
      }

      this.tmpSng = new L.Marker([car.lastPost.coord.latitude, car.lastPost.coord.longitude], {
        icon,
        zIndexOffset: 1000
      }).bindPopup(popUp);

      this.tmpSng.addTo(this.postSng);
    }
  }

  carPost(data: Emergency) {
    let username;
    switch (data.type) {
      case 'car':
        username = data.cid + ' - ' + data.name_device;
        break;

      default:
        username = data.username ? data.username : data.owner;
        break;
    }

    let date = new Date(data.published);

    const icon = L.icon({
      iconUrl: './assets/icon/' + data.type + '-old.png',
      iconAnchor: [25, 5],
      iconSize: [50, 50],
      popupAnchor: [0, -5]
    });
    const popUp =
      '<h4 class="ion-text-center">' +
      username +
      '</h4>' +
      '<p>' +
      data.body +
      '</p>' +
      '<p>' +
      data.direction +
      '</p>' +
      '<p>' +
      date +
      '</p>';

    const tmp = new L.Marker([data.coord.latitude, data.coord.longitude], {
      icon
    }).bindPopup(popUp);

    tmp.addTo(this.postGp);
  }

  //GENERAL POSTS
  postGeneralMapping() {
    this.map.addLayer(this.postGp);
    this.setControl.addOverlay(this.postGp, 'Usuarios');
    this.map.addLayer(this.postCarGp);
    this.setControl.addOverlay(this.postCarGp, 'Automóviles');
    this.map.addLayer(this.geofencesLayer);
    this.setControl.addOverlay(this.geofencesLayer, 'Geocercas');
  }

  lastGeneralPost(key: string, data: Emergency, index: number) {
    const iconUrl: string = './assets/icon/circle-reg.png';
    const icon = L.icon({
      iconUrl,
      iconSize: [50, 50],
      iconAnchor: [25, 5],
      popupAnchor: [0, -5]
    });

    const person = (data.username ? data.username : '') + ' ' + (data.owner ? data.owner : '');
    const popUp =
      '<h4 class="ion-text-center">' +
      data.username +
      '</h4>' +
      '<p>' +
      data.direction +
      '</p>' +
      '<p>' +
      data.published.toDate() +
      '</p>';
    const marker: L.Marker = L.marker([data.coord.latitude, data.coord.longitude], {
      icon,
      zIndexOffset: 1000
    }).bindPopup(popUp);
    marker['key'] = key;

    if (index !== -1) {
      this.lastPosts[index] = data;

      this.postGp.eachLayer((marker: L.Marker) => {
        if (marker['key'] == key) {
          const newLatLng = new L.LatLng(data.coord.latitude, data.coord.longitude);
          marker.setLatLng(newLatLng);
          marker.setPopupContent(popUp);
        }
      });
    } else {
      this.postGp.addLayer(marker);
    }
  }

  lastGeneralCarPost(key: string, data: Emergency, index: number) {
    const iconUrl: string = './assets/icon/car-reg.png';
    const icon = L.icon({
      iconUrl,
      iconSize: [50, 50],
      iconAnchor: [25, 5],
      popupAnchor: [0, -5]
    });

    const popUp =
      `<h4 class="ion-text-center">${data.name_device}</h4>` +
      `<ion-grid>` +
      `<ion-row><ion-col size="3">Dirección </ion-col> <ion-col size="9">${data.direction}</ion-col><ion-row>` +
      `<ion-row><ion-col size="3">Posición </ion-col> <ion-col size="9">${data.coord.latitude}, ${data.coord.longitude}</ion-col><ion-row>` +
      `<ion-row><ion-col size="3">Velocidad </ion-col> <ion-col size="9">${data['vel']} kph</ion-col><ion-row>` +
      `<ion-row><ion-col size="3">Odómetro </ion-col> <ion-col size="9">${data['odometro_kms']} km</ion-col><ion-row>` +
      `<ion-row><ion-col size="3">Hora </ion-col> <ion-col size="9">${data.published.toDate()}</ion-col><ion-row>` +
      `<ion-grid>`;
    const opt: L.PopupOptions = {
      minWidth: 300
    };

    const marker: L.Marker = L.marker([data.coord.latitude, data.coord.longitude], {
      icon,
      zIndexOffset: 1000
    }).bindPopup(popUp, opt);
    marker['key'] = key;

    if (index !== -1) {
      this.lastCarsPosts[index] = data;

      this.postCarGp.eachLayer((marker: L.Marker) => {
        if (marker['key'] == key) {
          const newLatLng = new L.LatLng(data.coord.latitude, data.coord.longitude);
          marker.setLatLng(newLatLng);
          marker.setPopupContent(popUp);
        }
      });
    } else {
      this.postCarGp.addLayer(marker);
    }
  }

  setViewGeneralPost(key: string) {
    this.postGp.eachLayer((marker: L.Marker) => {
      if (marker['key'] == key) {
        marker.fireEvent('click');
        this.setView({ latitude: marker.getLatLng().lat, longitude: marker.getLatLng().lng }, 15);
      }
    });
  }

  setViewGeneralCarPost(key: string) {
    this.postCarGp.eachLayer((marker: L.Marker) => {
      if (marker['key'] == key) {
        marker.fireEvent('click');
        this.setView({ latitude: marker.getLatLng().lat, longitude: marker.getLatLng().lng }, 15);
      }
    });
  }

  //FOLLOW ME POSTS
  postFollowMapping() {
    this.map.addLayer(this.lastLocGp);
    this.map.addLayer(this.lastLocLn);
    this.map.addLayer(this.lastLocMk);
    this.map.addLayer(this.postGp);
    this.setControl.addOverlay(this.postGp, 'Últimas ubicaciones');
    this.setControl.addOverlay(this.lastLocGp, 'Live Tracking (puntos)');
    this.setControl.addOverlay(this.lastLocLn, 'Live Tracking (línea)');
  }

  followMapping() {
    this.map.addLayer(this.layerOrigin);
    this.map.addLayer(this.layerDestination);
  }

  async postFollowUserMapping(posts: Array<any>) {
    this.postGp.clearLayers();
    await posts.map(data => {
      let username;
      switch (data.type) {
        case 'car':
          username = data.cid + ' - ' + data.name_device;
          break;

        default:
          username = data.username ? data.username : data.owner;
          break;
      }

      const icon = L.icon({
        iconUrl: './assets/icon/' + data.type + '-old.png',
        iconAnchor: [25, 5],
        iconSize: [50, 50],
        popupAnchor: [0, -5]
      });
      const popUp =
        '<h4 class="ion-text-center">' +
        username +
        '</h4>' +
        '<p>' +
        data.direction +
        '</p>' +
        '<p>' +
        this.datePipe.transform(data.published, 'medium') +
        '</p>';
      const tmp = new L.Marker([data.coord.latitude, data.coord.longitude], {
        icon
      })
        .bindPopup(popUp)
        .addTo(this.postGp);
    });
  }

  postOrigin(follow: Follow) {
    const iconorigin = L.icon({
      iconUrl: './assets/icon/person-reg.png',
      iconAnchor: [34, 5],
      iconSize: [68, 68],
      popupAnchor: [0, -5]
    });

    const originPop =
      '<ion-text color="primary"><h3 class="ion-text-center">Origen Follow me</h3></ion-text>' +
      '<h5 class="ion-text-center">' +
      follow.username +
      '</h5>' +
      '<p>' +
      follow.origin.direction +
      '</p>';
    const marker: L.Marker = L.marker([follow.origin.coord.latitude, follow.origin.coord.longitude], {
      icon: iconorigin
    }).bindPopup(originPop);
    marker.addTo(this.map);
  }

  postDestination(follow: Follow) {
    const icon = L.icon({
      iconUrl: './assets/icon/flag.png',
      iconSize: [50, 50]
    });

    const destinationPop =
      '<ion-text color="primary"><h3 class="ion-text-center">Destino Follow me</h3></ion-text>' +
      '<h5 class="ion-text-center">' +
      follow.username +
      '</h5>' +
      '<p>' +
      follow.origin.direction +
      '</p>' +
      '<p>' +
      this.datePipe.transform(follow.published.toDate(), 'medium') +
      '</p>';
    const tmp = new L.Marker([follow.destination.coord.latitude, follow.destination.coord.longitude], {
      icon
    }).bindPopup(destinationPop);
    tmp.addTo(this.map);
  }

  removeFollow() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.postGp);
    this.setControl.removeLayer(this.lastLocGp);
    this.setControl.removeLayer(this.lastLocLn);
    this.setControl.removeLayer(this.lastLocMk);
    this.zonePol.clearLayers();
    this.quadPol.clearLayers();
    this.postSng.clearLayers();
    this.postGp.clearLayers();
    this.map.removeLayer(this.postSng);
    this.map.removeLayer(this.postGp);
    this.map.removeLayer(this.lastLocGp);
    this.map.removeLayer(this.lastLocLn);
    this.map.removeLayer(this.lastLocMk);
  }

  //TRACKING POSTS
  postTrackingMapping() {
    //this.map.addLayer(this.lastLocGp);
    this.map.addLayer(this.lastLocLn);
    this.map.addLayer(this.lastLocMk);
    this.map.addLayer(this.postGp);
    this.setControl.addOverlay(this.postGp, 'Últimas ubicaciones');
    this.setControl.addOverlay(this.lastLocGp, 'Live Tracking (puntos)');
    this.setControl.addOverlay(this.lastLocLn, 'Live Tracking (línea)');
  }

  async postTrackingUserMapping(posts: Array<any>) {
    this.postGp.clearLayers();
    await posts.map(data => {
      let username;
      switch (data.type) {
        case 'car':
          username = data.cid + ' - ' + data.name_device;
          break;

        default:
          username = data.username ? data.username : data.owner;
          break;
      }

      const icon = L.icon({
        iconUrl: './assets/icon/' + data.type + '-old.png',
        iconAnchor: [25, 5],
        iconSize: [50, 50],
        popupAnchor: [0, -5]
      });
      const popUp =
        '<h4 class="ion-text-center">' +
        username +
        '</h4>' +
        '<p>' +
        data.direction +
        '</p>' +
        '<p>' +
        this.datePipe.transform(data.published, 'medium') +
        '</p>';
      const tmp = new L.Marker([data.coord.latitude, data.coord.longitude], {
        icon
      })
        .bindPopup(popUp)
        .addTo(this.postGp);
    });
  }

  trackingOrigin(follow: Follow) {
    const iconorigin = L.icon({
      iconUrl: './assets/icon/person-reg.png',
      iconAnchor: [34, 5],
      iconSize: [68, 68],
      popupAnchor: [0, -5]
    });

    const originPop =
      '<ion-text color="primary"><h3 class="ion-text-center">Origen Follow me</h3></ion-text>' +
      '<h5 class="ion-text-center">' +
      follow.username +
      '</h5>' +
      '<p>' +
      follow.origin.direction +
      '</p>';
    const marker: L.Marker = L.marker([follow.origin.coord.latitude, follow.origin.coord.longitude], {
      icon: iconorigin
    }).bindPopup(originPop);
    marker.addTo(this.map);
  }

  trackingDestination(follow: Follow) {
    const icon = L.icon({
      iconUrl: './assets/icon/flag.png',
      iconSize: [50, 50]
    });

    const destinationPop =
      '<ion-text color="primary"><h3 class="ion-text-center">Destino Follow me</h3></ion-text>' +
      '<h5 class="ion-text-center">' +
      follow.username +
      '</h5>' +
      '<p>' +
      follow.origin.direction +
      '</p>' +
      '<p>' +
      this.datePipe.transform(follow.published.toDate(), 'medium') +
      '</p>';
    const tmp = new L.Marker([follow.destination.coord.latitude, follow.destination.coord.longitude], {
      icon
    }).bindPopup(destinationPop);
    tmp.addTo(this.map);
  }

  setViewTrackingPost(key: string, click?: boolean) {
    this.lastLocMk.eachLayer((marker: L.Marker) => {
      if (marker['key'] == key) {
        if (click || click === undefined) {
          marker.fireEvent('click');
        }
        this.setView({ latitude: marker.getLatLng().lat, longitude: marker.getLatLng().lng }, 15);
      }
    });
    this.lastLocGp.eachLayer((marker: L.Marker) => {
      if (marker['key'] == key) {
        marker.fireEvent('click');
        this.setView({ latitude: marker.getLatLng().lat, longitude: marker.getLatLng().lng }, 15);
      }
    });
  }

  removeTracking() {
    this.setControl.removeLayer(this.zonePol);
    this.setControl.removeLayer(this.quadPol);
    this.setControl.removeLayer(this.postGp);
    this.setControl.removeLayer(this.lastLocGp);
    this.setControl.removeLayer(this.lastLocLn);
    this.setControl.removeLayer(this.lastLocMk);
    this.zonePol.clearLayers();
    this.quadPol.clearLayers();
    this.postSng.clearLayers();
    this.postGp.clearLayers();
    this.map.removeLayer(this.postSng);
    this.map.removeLayer(this.postGp);
    this.map.removeLayer(this.lastLocGp);
    this.map.removeLayer(this.lastLocLn);
    this.map.removeLayer(this.lastLocMk);
  }

  //HELPERS
  setView(coord: Coords, zoom: number) {
    this.map.setView([coord.latitude, coord.longitude], zoom);
  }

  //MATHS DATA
  getDistanceFromLatLonInKm(coord1: Coords, coord2: Coords) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(coord1.latitude - coord2.latitude); // deg2rad below
    const dLon = this.deg2rad(coord1.longitude - coord2.longitude);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(coord1.latitude)) *
        Math.cos(this.deg2rad(coord2.latitude)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }

  async getAddress(coords: Coords, nameOnly?: boolean) {
    try {
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };

      const params =
        'https://nominatim.openstreetmap.org/reverse?format=jsonv2&' +
        'lat=' +
        coords.latitude +
        '&lon=' +
        coords.longitude;
      const req = await this.http.get(params, httpOptions).toPromise();
      return nameOnly ? (req['name'] ? req['name'] : req['address'].road) : req['display_name'];
    } catch (error) {
      return '';
    }
  }

  corporateZones(corporate: string) {
    if (this.map) {
      this.corporateGeoLayer.addTo(this.map);
    }

    if (this.dataService.corporategeo.length > 0) {
      this.corporategeo = this.dataService.corporategeo;
      this.evaluategeofences(this.corporategeo);
    } else {
      this.dataService.getCorporateZones(corporate);
    }

    this.corZonesSub = this.dataService.sendGeofences$.subscribe(() => {
      if (this.drawGeofences) {
        this.corporategeo = this.dataService.corporategeo;
        this.evaluategeofences(this.corporategeo);
      }
    });
  }

  evaluategeofences(geofences: string | any[]) {
    for (let index = 0; index < geofences.length; index++) {
      const element = geofences[index];
      this.evaluatefence(element);
    }
  }

  evaluatefence(element: any) {
    const id = element.id ? element.id : element.key;
    const name = element.name ? element.name : 'Sin título';
    const origin = element.origin ? element.origin : 'war room';
    const risk_level_id = element.risk_level_id ? element.risk_level_id : null;
    const serverId = element.serverId ? element.serverId : null;
    let figure: L.Layer | L.Circle | L.Polygon;

    this.corporateGeoLayer.eachLayer(layer => {
      if (layer['key']) {
        if (layer['key'] == id) {
          this.corporateGeoLayer.removeLayer(layer);
        }
      }
    });

    const color = element.color ? element.color : '#808080';

    switch (element.shape.toLowerCase()) {
      case 'circle':
        const point = {
          lat: element.center.latitude ? element.center.latitude : element.center.lat,
          lng: element.center.longitude ? element.center.longitude : element.center.lng
        };
        const radius = element.radius;
        figure = new L.Circle(point, radius);
        break;
      case 'polygon':
        const evaluationpoints = element.points ? element.points : element.latlngs;
        let points = [];
        for (let index = 0; index < evaluationpoints.length; index++) {
          const element1 = evaluationpoints[index];
          const obj = {
            lat: element1.latitude ? element1.latitude : element1.lat,
            lng: element1.longitude ? element1.longitude : element1.lng
          };
          points.push(obj);
        }

        figure = new L.Polygon(points);
        break;

      default:
        break;
    }

    figure['key'] = id;
    figure['name'] = name;
    figure['origin'] = origin;
    figure['setStyle']({ color: color });
    figure['risk_level_id'] = risk_level_id;
    figure['serverId'] = serverId;

    this.addgeofence(figure);
  }

  addgeofence(figure: L.Layer | L.Polygon | L.Circle) {
    figure.addTo(this.corporateGeoLayer);
    this.setPopUp(figure);
    this.getList();

    figure.on('pm:edit', async e => {
      const layer = await this.checkType(e.layer);
      layer.key = e.layer['key'] ? e.layer['key'] : e.layer['_leaflet_id'];
      layer['shape'] = e.shape.toLocaleLowerCase();

      this.evaluatefence(layer);
    });

    figure.on('pm:remove', e => {
      this.getList();
    });
  }

  setPopUp(e: L.Polygon | L.Circle | L.Layer) {
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;
    const rootPath = s[0].path;
    if (rootPath == 'geofences') {
      /* Crear popup infowindow */

      //Usar this_ como variable local
      let this_ = this;

      //Div padre en InfoWindow
      var parentDiv = document.createElement('div');
      parentDiv.className = 'parentDiv';

      /*     //Información del post
    var infoDiv = document.createElement('div');
    let infoData = '';
    const title = e['name'] ? `<b> ${e['name']} </b> <br>` : `<b> Sin título </b> <br>`;
    infoData = title;
    infoDiv.innerHTML = infoData;

    parentDiv.appendChild(infoDiv); */

      //Div padre de botones
      var parentButtons = document.createElement('div');
      parentButtons.className = 'parentButtons';

      const name = document.createElement('input');
      name.id = 'geofence-id';
      name.type = 'text';
      name.value = e['name'];

      const deleteBtn = document.createElement('div');
      deleteBtn.innerHTML = `<ion-button expand="block" color="danger" fill="solid" style="width:100%>
          <ion-icon slot="end" name="trash-outline"></ion-icon>
          <ion-label>ELIMINAR</ion-label>
        </ion-button>`;
      deleteBtn.addEventListener('click', function() {
        /* Abrir street view */
        this_.delete(e);
      });

      const saveBtn = document.createElement('div');
      saveBtn.innerHTML = `<ion-button expand="block" color="primary" fill="solid" style="width:100%
          (click)="save()"
        >
          <ion-icon slot="end" name="save-outline"></ion-icon>
          <ion-label>GUARDAR</ion-label>
        </ion-button>`;
      saveBtn.style.width = '%100';
      saveBtn.addEventListener('click', function() {
        /* Abrir street view */
        this_.saveData(e);
      });

      parentButtons.appendChild(name);
      parentButtons.appendChild(saveBtn);
      parentButtons.appendChild(deleteBtn);
      parentDiv.appendChild(parentButtons);

      const popUp = parentDiv;
      const p = new L.Popup({ autoClose: true, closeOnClick: true }).setContent(popUp);
      e.bindPopup(p);
    }
  }

  saveData(layer: L.Circle | L.Polygon | L.Layer) {
    const geofenceId = document.getElementById('geofence-id');
    const name = geofenceId['value'] ? geofenceId['value'] : 'Sin título';

    const index = this.tmpcorporategeo.findIndex(x => {
      return x.key == layer['key'];
    });

    this.tmpcorporategeo[index].name = name;
    layer['name'] = name;
    layer.closePopup();
    layer.openPopup();
  }

  changeRisk(ev: any, key: string | number) {
    this.map.eachLayer(layer => {
      if (layer['key'] == key) {
        const idx = this.zoneLevels.findIndex(x => {
          return x.id == ev.detail.value;
        });

        layer['risk_level_id'] = ev.detail.value;
        layer['setStyle']({ color: this.zoneLevels[idx].color });

        const index = this.tmpcorporategeo.findIndex(x => {
          return x.key == layer['key'];
        });

        this.tmpcorporategeo[index].risk_level_id = ev.detail.value;
        this.tmpcorporategeo[index].color = this.zoneLevels[idx].color;
      }
    });
  }

  delete(e: L.Layer) {
    this.corporateGeoLayer.removeLayer(e);
    this.getList();
  }

  async checkType(layer: any) {
    const shape = layer['_mRadius'] ? 'circle' : 'polygon';
    const key = layer['key'] ? layer['key'] : null;
    const origin = layer['origin'] ? layer['origin'] : 'war room';
    const color = layer['options'].color ? layer['options'].color : '#808080';
    const risk_level_id = layer['risk_level_id'] ? layer['risk_level_id'] : 'none';

    let zone = {
      shape: shape,
      key: key,
      name: '',
      origin,
      color: color,
      risk_level_id: risk_level_id
    };

    switch (shape) {
      case 'circle':
        zone['radius'] = layer['_mRadius'];
        zone['center'] = layer['_latlng'];
        break;
      case 'polygon':
        let latlngs = [];
        for (let index = 0; index < layer['_latlngs'][0].length; index++) {
          const element = layer['_latlngs'][0][index];
          latlngs.push(element);
        }
        latlngs.push(layer['_latlngs'][0][0]);
        zone['points'] = latlngs;
        zone['center'] = layer['_bounds'].getCenter();
        break;

      default:
        break;
    }
    const name = layer['name']
      ? layer['name']
      : await this.getAddress({ latitude: zone['center'].lat, longitude: zone['center'].lng }, true);
    zone.name = name;

    return zone;
  }

  async getList() {
    let zones: { shape: string; key: any }[] = [];
    this.corporateGeoLayer.eachLayer(async (layer: L.Circle | L.Polygon | L.Layer) => {
      const zone = await this.checkType(layer);
      zones.push(zone);
    });
    this.tmpcorporategeo = zones;
  }

  subscribetoevents() {
    this.map.on('pm:create', async ({ layer }) => {
      layer['setStyle']({ color: '#808080' });
      layer['key'] = layer['_leaflet_id'];
      layer['origin'] = 'war room';
      layer['color'] = '#808080';
      layer['risk_level_id'] = 'none';
      const converted = await this.checkType(layer);
      layer['name'] = converted.name;
      layer['shape'] = converted.name;

      this.getList();
      this.setPopUp(layer);
      layer.fireEvent('click');

      layer.on('pm:edit', async e => {
        const lay = await this.checkType(e.layer);
        lay['shape'] = e.shape != 'Rectangle' ? e.shape : 'polygon';
        this.evaluatefence(lay);
      });
    });

    this.map.on('pm:remove', ({ layer }) => {
      this.getList();
    });
  }

  setViewGeofence(key: string) {
    this.corporateGeoLayer.eachLayer((layer: L.Circle | L.Polygon) => {
      if (layer['key'] == key) {
        layer.fireEvent('click');
        const coord = layer['_latlng']
          ? this.map.flyToBounds(layer.getBounds())
          : this.map.flyToBounds(layer['_bounds']);
      }
    });
  }

  openWindow(url: string) {
    window.open(url, 'Google Maps');
  }
}
