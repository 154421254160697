import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { LoadingController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  corporateZones: any = [];
  sendGeofences = new Subject<boolean>();
  sendGeofences$ = this.sendGeofences.asObservable();
  corporategeo: any[] = [];
  numbers: any[] = [];
  circles: Array<any> = [];
  users: Array<any> = [];
  devicesType: any[] = [];
  isVideoEnabled: boolean = true;

  tmpUsers: any[] = [];
  tmpCircles: any[] = [];

  search: boolean = false;
  search_timeout: any;

  /* switches */
  traffic: boolean = false;

  constructor(
    public afd: AngularFireDatabase,
    public afs: AngularFirestore,
    private loadingController: LoadingController
  ) {
    this.getDeviceTypes();
  }

  getCorporateZones(corporate: string) {
    const cor = corporate != 'risk' ? corporate : 'risk';
    const route = cor == 'risk' ? `risk` : `geofences/${cor}/geofences`;

    this.afs
      .collection(route)
      .snapshotChanges()
      .pipe(
        map(posts =>
          posts.map((post: any) => {
            const geo: any = post.payload.doc.data();
            geo.id = post.payload.doc.id;
            geo.origin = 'firebase';

            return geo;
          })
        )
      )
      .subscribe(val => {
        this.corporategeo = val;
        this.sendGeofences.next();
      });
  }

  getDeviceTypes() {
    this.afs
      .collection(`devicesType`)
      .snapshotChanges()
      .pipe(
        take(1),
        map(posts =>
          posts.map((post: any) => {
            const type: any = post.payload.doc.data();
            type.id = post.payload.doc.id;

            return type;
          })
        )
      )
      .subscribe(val => {
        this.devicesType = val;
      });
  }

  async getNumbers() {
    this.afs
      .collection(`/third-party/diatel/records`)
      .snapshotChanges()
      .subscribe(data => {
        let tmp: unknown[] = [];
        data.forEach(phone => {
          const element = phone.payload.doc.data();
          tmp.push(element);
        });
        this.numbers = tmp;
      });
  }

  async getCircles() {
    const loading = await this.loadingController.create({
      message: 'Obteniendo usuarios...',
      spinner: 'bubbles'
    });
    await loading.present();

    let tmp: unknown[] = [];

    this.afs
      .collection(`circles`, ref => ref.where('test', '!=', true) /* .limit(10) */)
      .stateChanges()
      .pipe(take(1))
      .subscribe(data => {
        console.log('loading fs circles', data.length);
        data.forEach(phone => {
          const element = phone.payload.doc.data();
          element['id'] = phone.payload.doc.id;

          if (!element['deletedInfo']) {
            const idx = tmp.findIndex(x => {
              return element['id'] == x['id'];
            });

            if (idx < 0) {
              tmp.push(element);
            }
          }
        });

        this.afd
          .list(`circles` /* , ref => ref.limitToFirst(10) */)
          .snapshotChanges()
          .pipe(take(1))
          .subscribe(data => {
            console.log('loading rt circles', data.length);

            data.forEach(phone => {
              let element = phone.payload.val();

              if (!element['test'] && !element['deleted']) {
                element['id'] = phone.key;

                const idx = tmp.findIndex(x => {
                  return element['id'] == x['id'];
                });
                if (idx < 0) {
                  element['alias'] = element['id'];
                  tmp.push(element);
                }
              }
            });
            this.circles = tmp;
            loading.dismiss();
          });
      });
  }

  async getUsers() {
    const loading = await this.loadingController.create({
      message: 'Obteniendo usuarios...',
      spinner: 'bubbles'
    });
    await loading.present();

    let tmp: unknown[] = [];

    this.afs
      .collection(`users`, ref => ref.where('test', '!=', true) /* .limit(10) */)
      .stateChanges()
      .pipe(take(1))
      .subscribe(data => {
        console.log('loading fs users', data.length);

        data.forEach(phone => {
          const element = phone.payload.doc.data();
          element['id'] = phone.payload.doc.id;

          const idx = tmp.findIndex(x => {
            return element['id'] == x['id'];
          });

          if (idx < 0) {
            tmp.push(element);
          }
        });

        this.afd
          .list(`users` /* , ref => ref.limitToFirst(10) */)
          .snapshotChanges()
          .pipe(take(1))
          .subscribe(data => {
            console.log('loading rt users', data.length);

            data.forEach(phone => {
              const element = phone.payload.val();

              if (!element['test'] && !element['deleted']) {
                element['id'] = phone.key;

                const idx = tmp.findIndex(x => {
                  return element['id'] == x['id'];
                });
                if (idx < 0) {
                  tmp.push(element);
                }
              }
            });

            this.users = tmp;
            loading.dismiss();
          });
      });
  }

  getUserItems(ev: any) {
    const val: string = ev.target.value;
    if (val == '') {
      this.tmpUsers = this.users;
      this.search = false;
      return;
    }

    this.search = true;

    if (this.search_timeout) {
      clearTimeout(this.search_timeout);
    }

    this.search_timeout = setTimeout(async () => {
      let tmpUsers: any[] = [];
      this.users.forEach(user => {
        switch (true) {
          case user.username && user.username.search(val.toUpperCase()) !== -1:
          case user.cid && user.cid.search(val.toUpperCase()) !== -1:
          case user.email && user.email.search(val.toLowerCase()) !== -1:
            tmpUsers.push(user);

            break;

          default:
            break;
        }
      });

      this.tmpUsers = tmpUsers;
    }, 2000);
  }

  getCircleItems(ev: any) {
    const val: string = ev.target.value;
    if (val == '') {
      this.tmpCircles = this.circles;
      this.search = false;
      return;
    }

    this.search = true;

    if (this.search_timeout) {
      clearTimeout(this.search_timeout);
    }

    this.search_timeout = setTimeout(async () => {
      let tmpCircles: any[] = [];
      this.circles.forEach(circle => {
        switch (true) {
          case circle.alias && circle.alias.search(val.toUpperCase()) !== -1:
            tmpCircles.push(circle);

            break;

          default:
            break;
        }
      });

      this.tmpCircles = tmpCircles;
    }, 2000);
  }

  async videoEnable(corporate: string) {
    this.isVideoEnabled = true;
    this.afs
      .doc(`group/${corporate}`)
      .snapshotChanges()
      .subscribe(val => {
        if (val.payload.exists) {
          this.isVideoEnabled = val.payload.data()['videoCall']
            ? Boolean(val.payload.data()['videoCall'])
            : this.isVideoEnabled;
        }
      });
  }
}
