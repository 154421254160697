import { Component, OnInit, Input } from '@angular/core';

import { environment } from '@env/environment';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DatasheetService } from '@app/core/db/datasheet.service';
import { Datasheet } from '@app/core/db/datasheet.model';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { CredentialsService } from '@app/core';

@Component({
  selector: 'app-datasheet',
  templateUrl: './datasheet.component.html',
  styleUrls: ['./datasheet.component.scss']
})
export class DatasheetComponent implements OnInit {
  @Input() id: string;
  @Input() type: string;
  datasheet: Datasheet;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '50',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Detalla la ficha a continuación',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [{ class: 'sans-serif', name: 'Roboto' }],
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['subscript', 'superscript'], ['font'], ['clearFormatting'], ['insertImage', 'insertVideo']]
  };
  circleDatasheet: Datasheet;
  circleProtocol: string;
  circleId: string;
  personDatasheet: Datasheet;
  personProtocol: string;
  personId: string;
  editmode = false;
  private itemDoc: AngularFirestoreDocument<Datasheet>;
  private item: Observable<Datasheet>;
  constructor(
    private activatedRoute: ActivatedRoute,
    private afs: AngularFirestore,
    private credSrv: CredentialsService
  ) {}
  ngOnInit() {}
  ionViewDidEnter() {
    if (this.type === 'circle') {
      this.circleId = this.id;
    } else if (this.type === 'person') {
      this.circleId = this.id.substring(0, this.id.length - 3).toLowerCase();
      this.personId = this.id;
    }
    this.afs
      .doc<Datasheet>('datasheet/' + this.circleId.toLowerCase())
      .valueChanges()
      .subscribe(data => {
        this.circleDatasheet = data;
        this.circleProtocol = data.body;
      });
    if (this.type === 'person') {
      this.afs
        .doc<Datasheet>('datasheet/' + this.circleId.toLowerCase() + '/children/' + this.personId.toLowerCase())
        .valueChanges()
        .subscribe(data => {
          if (data) {
            this.personDatasheet = data;
            this.personProtocol = this.personDatasheet.body;
          }
        });
    }
  }
  saveData() {
    if (this.type === 'circle') {
      this.afs.doc<Datasheet>('datasheet/' + this.id.toLowerCase()).set({
        circleId: this.id.toUpperCase(),
        body: this.circleProtocol,
        lastUpdate: { user: this.credSrv.credentials.username, date: new Date() }
      });
    } else if (this.type === 'person') {
      this.afs
        .doc<Datasheet>('datasheet/' + this.circleId.toLowerCase() + '/children/' + this.personId.toLowerCase())
        .set({
          circleId: this.circleId.toUpperCase(),
          body: this.personProtocol,
          id: this.personId.toUpperCase(),
          lastUpdate: { user: this.credSrv.credentials.username, date: new Date() }
        });
    }
    this.editmode = !this.editmode;
  }
  editToggle() {
    this.editmode = !this.editmode;
  }
}
