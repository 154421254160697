import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Logger, I18nService, AuthenticationService, untilDestroyed, CredentialsService } from '@app/core';
import { LoadingController } from '@ionic/angular';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    public http: HttpClient,
    private authenticationService: AuthenticationService,
    private creds: CredentialsService,
    public loadingCtrl: LoadingController
  ) {}

  /* users */
  async updateUser(uid: string, data: any) {
    const loadingOverlay = await this.loadingCtrl.create({});
    loadingOverlay.present();

    const token = await this.authenticationService.getTokenid(false);

    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: '*/*',
          'cache-control': 'no-cache',
          Authorization: 'Bearer ' + token
        })
      };
      await this.http.put(environment.CLOUD + `/registerUser/${uid}`, JSON.stringify(data), httpOptions).toPromise();
    } catch (error) {}

    loadingOverlay.dismiss();

    return;
  }

  async createUser(data: any) {
    const loadingOverlay = await this.loadingCtrl.create({});
    loadingOverlay.present();

    const token = await this.authenticationService.getTokenid(false);

    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: '*/*',
          'cache-control': 'no-cache',
          Authorization: 'Bearer ' + token
        })
      };
      await this.http.post(environment.CLOUD + `/registerUser`, JSON.stringify(data), httpOptions).toPromise();
    } catch (error) {}

    loadingOverlay.dismiss();

    return;
  }

  async updatePassword(email: string, newPwd: string) {
    const loadingOverlay = await this.loadingCtrl.create({});
    loadingOverlay.present();

    const token = await this.authenticationService.getTokenid(false);

    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: '*/*',
          'cache-control': 'no-cache',
          Authorization: 'Bearer ' + token
        })
      };
      await this.http
        .put(environment.CLOUD + `/registerUser/changepassword/${email}`, JSON.stringify({ pwd: newPwd }), httpOptions)
        .toPromise();
    } catch (error) {}

    loadingOverlay.dismiss();

    return;
  }

  async deleteUser(uid: string) {
    const loadingOverlay = await this.loadingCtrl.create({});
    loadingOverlay.present();

    const token = await this.authenticationService.getTokenid(false);

    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: '*/*',
          'cache-control': 'no-cache',
          Authorization: 'Bearer ' + token
        })
      };
      await this.http.delete(environment.CLOUD + `/registerUser/${uid}`, httpOptions).toPromise();
    } catch (error) {}

    loadingOverlay.dismiss();

    return;
  }

  async getMembershipInfo(type: string, uid: string) {
    console.log(type, uid);
    //const loadingOverlay = await this.loadingCtrl.create({});
    //loadingOverlay.present();

    const token = await this.authenticationService.getTokenid(false);
    let info: any = {};

    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: '*/*',
          'cache-control': 'no-cache',
          Authorization: 'Bearer ' + token
        })
      };
      info = await this.http.get(environment.CLOUD + `/usersApp/membership/${uid}`, httpOptions).toPromise();
    } catch (error) {
      console.error('error getting membership', error);
    }

    //loadingOverlay.dismiss();

    console.log(info);
    return info;
  }

  /* geofences */

  async setCorporateGeofences(data: any) {
    const loadingOverlay = await this.loadingCtrl.create({});
    loadingOverlay.present();

    const token = await this.authenticationService.getTokenid(false);

    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: '*/*',
          'cache-control': 'no-cache',
          Authorization: 'Bearer ' + token
        })
      };
      const group = environment.GROUP != '' ? environment.GROUP : this.creds.credentials.group;
      await this.http
        .post(`${environment.CLOUD}/geofencesApp/corporate/${group.toLowerCase()}`, JSON.stringify(data), httpOptions)
        .toPromise();
    } catch (error) {}

    loadingOverlay.dismiss();

    return;
  }

  async getRisk(id: string) {
    const loadingOverlay = await this.loadingCtrl.create({});
    loadingOverlay.present();

    const token = await this.authenticationService.getTokenid(false);
    let risk = {};

    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: '*/*',
          'cache-control': 'no-cache',
          Authorization: token
        })
      };
      risk = await this.http.get(`http://35.222.151.64:3000/getRSCombinedAgeb/${id}`, httpOptions).toPromise();
    } catch (error) {}

    loadingOverlay.dismiss();

    return risk;
  }

  /* 
  BITLY
  async shortUrl(url: string) {
    const loadingOverlay = await this.loadingCtrl.create({});
    loadingOverlay.present();

    const token = environment.BITLY;
    let shortUrl = {};

    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'cache-control': 'no-cache',
          Authorization: `Bearer ${token}`
        })
      };

      const data = {
        link: url,
        //id: 'string',
        long_url: url
        //created_at: 'string',
        //tags: ['string']
      };

      shortUrl = await this.http
        .post(`https://api-ssl.bitly.com/v4/shorten`, JSON.stringify(data), httpOptions)
        .toPromise();
    } catch (error) {}

    loadingOverlay.dismiss();

    return shortUrl;
  } */

  /* SHORT IO */
  async shortUrl(url: string) {
    const loadingOverlay = await this.loadingCtrl.create({});
    loadingOverlay.present();

    //const token = environment.BITLY;
    const token = environment.SHORTIO;
    const domain = environment.SHORTIO_URL;
    let shortUrl = {};

    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: '*/*',
          'cache-control': 'no-cache',
          Authorization: `${token}`
        })
      };

      const data = {
        originalURL: url,
        domain: domain
      };

      /*
      BITLY
      shortUrl =  await this.http
        .post(`https://api-ssl.bitly.com/v4/shorten`, JSON.stringify(data), httpOptions)
        .toPromise(); */

      shortUrl = await this.http.post(`https://api.short.io/links`, JSON.stringify(data), httpOptions).toPromise();

      console.log(shortUrl);
    } catch (error) {}

    loadingOverlay.dismiss();

    return shortUrl;
  }

  async sendSMS(message: string, phones: Array<string>) {
    const loadingOverlay = await this.loadingCtrl.create({});
    loadingOverlay.present();

    const token = await this.authenticationService.getTokenid(true);
    let smsResponse = {};

    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Accept: '*/*',
          'cache-control': 'no-cache',
          Authorization: `Bearer ${token}`
        })
      };

      let data = {
        text: message,
        phones: phones
      };

      /* let data = {
        text: message,
        phones: ['+52 55 1811 8908', '+525532844490']
      }; */

      await this.http.post(environment.CLOUD + `/server/sendSMS`, JSON.stringify(data), httpOptions).toPromise();
    } catch (error) {}

    loadingOverlay.dismiss();

    return smsResponse;
  }

  /*   var request = require('request');
var options = {
  'method': 'GET',
  'url': 'http://35.222.151.64:3000/getRSCombinedAgeb/0900200010010',
  'headers': {
    'Authorization': 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImJlYmYxMDBlYWRkYTMzMmVjOGZlYTU3ZjliNWJjM2E2YWIyOWY1NTUiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiRGF2aWQgUMOpcmV6IiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FBVFhBSnl6YVg4Y3owdVRUejhvTjNGYUFlMUN2TjU0aXZYLWxzaUJZNnVNPXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3RydXN0LTExYjdkIiwiYXVkIjoidHJ1c3QtMTFiN2QiLCJhdXRoX3RpbWUiOjE2NDYyNTc5MDMsInVzZXJfaWQiOiJIc0ZXWHJqNmdMWTlxR3c2RUZkQ2RCSDVPdUkzIiwic3ViIjoiSHNGV1hyajZnTFk5cUd3NkVGZENkQkg1T3VJMyIsImlhdCI6MTY1MjEzNzAyMiwiZXhwIjoxNjUyMTQwNjIyLCJlbWFpbCI6ImRwZXJlekB0ZWNub3RydXN0LmNvbS5teCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7Imdvb2dsZS5jb20iOlsiMTAxMTA4ODU0NzM0MjAxMTYwNjU0Il0sImVtYWlsIjpbImRwZXJlekB0ZWNub3RydXN0LmNvbS5teCJdfSwic2lnbl9pbl9wcm92aWRlciI6Imdvb2dsZS5jb20ifX0.gEHU5syZ-yCa_laksG05n_pUe4UqxsD-xMrAGXTnqMP26ccHe297qjGMVvdroMBYvh2gaURPW-FqgpqXPrS5UVId0-QRzxADdTVq6zKVCZiOzKHJrpXMiFw3H8fKXxF3m9GfC0BvrcuAw9m1M39vVxHmJd_VQEyoUwidIk7h6NnUG8jvy-JPE2IIiI6g1prEafWmuHBhB9-nKA_afz9zd6nK8CoTgErhoDiSL8NvCxTM9AD7aHLm99_NWCstDlagVeUKlPOkdXWviUdHUZUsJ9dp5zUSThcVolGn1-BRGbcTxNY1RJKo7gh6cPUNWkW4lR2ykCg5rWen8bwlCfqxDg'
  }
};
request(options, function (error, response) {
  if (error) throw new Error(error);
  console.log(response.body);
}); */
}
