import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([{ path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) }]),
  Shell.childRoutes([
    { path: 'general', loadChildren: () => import('./general/general.module').then(m => m.GeneralModule) }
  ]),
  Shell.childRoutes([
    { path: 'follow', loadChildren: () => import('./follow/follow.module').then(m => m.FollowModule) }
  ]),
  Shell.childRoutes([
    { path: 'circle/:id', loadChildren: () => import('./circle/circle.module').then(m => m.CircleModule) }
  ]),
  Shell.childRoutes([
    { path: 'person/:cid/:pid', loadChildren: () => import('./person/person.module').then(m => m.PersonModule) }
  ]),
  Shell.childRoutes([
    { path: 'emergency/:eid', loadChildren: () => import('./emergency/emergency.module').then(m => m.EmergencyModule) }
  ]),
  Shell.childRoutes([{ path: 'car/:cid/:uid', loadChildren: () => import('./car/car.module').then(m => m.CarModule) }]),
  Shell.childRoutes([
    { path: 'followme/:fid', loadChildren: () => import('./followme/followme.module').then(m => m.FollowmeModule) }
  ]),
  Shell.childRoutes([
    { path: 'tracking/:cid/:uid', loadChildren: () => import('./tracking/tracking.module').then(m => m.TrackingModule) }
  ]),
  Shell.childRoutes([
    { path: 'history/:cid/:uid', loadChildren: () => import('./history/history.module').then(m => m.HistoryModule) }
  ]),
  Shell.childRoutes([
    {
      path: 'geofences/:group',
      loadChildren: () => import('./geofences/geofences.module').then(m => m.GeofencesModule)
    }
  ]),

  /*   Shell.childRoutes([{ path: 'datasheet/:id', loadChildren: './shared/datasheet/datasheet.module#DatasheetModule' }]), */

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
