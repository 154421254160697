import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  UrlSegment
} from '@angular/router';

import { Logger } from '../logger.service';
import { CredentialsService } from './credentials.service';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  // declare a variable 'routeURL'
  // to keep track of current active route
  routeURL: string;

  constructor(private router: Router, private credentialsService: CredentialsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.routeURL = state.url;

    const tree: UrlTree = this.router.parseUrl(state.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;
    const rootPath = s[0].path;

    if (this.credentialsService.isAuthenticated()) {
      if (this.credentialsService.credentials.role == 'admin') {
        return true;
      } else {
        //only Home is available for non-admin users
        if (rootPath != 'home') {
          this.router.navigate(['/home'], {
            queryParams: {
              redirect: state.url
            },
            replaceUrl: true
          });

          return false;
        }

        return true;
      }
    }

    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.router.navigate(['/login'], {
      queryParams: {
        redirect: state.url
      },
      replaceUrl: true
    });
    return false;
  }
}
